import React, { useState, useEffect } from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'

const Box = ({ defaultContent, overlayContent }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTimeout(() => setIsArrowRotated(true), 500); // 500ms delay to match the text transition duration
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsArrowRotated(false);
  };

  return (
    <div
      className="flex-1 relative overflow-hidden aspect-[23/29]"
      style={{
        borderRadius: '12px',  // 自定义圆角大小
        boxShadow: '6px 6px 8px rgba(0, 0, 0, 0.3)',  // 自定义阴影
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`bg-cover bg-center text-white text-left p-2 border h-full`} style={{
        backgroundImage: `url(${defaultContent.image})`, borderRadius: '12px',  // 自定义圆角大小
        boxShadow: '6px 6px 8px rgba(0, 0, 0, 0.3)'
      }}>
        <p className={`mt-[99%] 1440xl:h-[2rem] h-[2vw] 1440xl:text-[1rem] text-[1vw] font-noto font-light ${isHovered ? 'opacity-0' : 'opacity-100'}`}>{defaultContent.text}</p>
        <div className='flex flex-wrap w-full justify-between'>
          <p className={`1440xl:text-[0.61rem] text-[0.61vw] font-noto font-light ${isHovered ? 'opacity-0' : 'opacity-100'}`}>{defaultContent.texten}</p>
          <img src="arrow.png" className='xl:w-[15px] md:w-[10px] w-[5px] xl:h-[7px] md:h-[4px] h-[2px] mr-[5px]'></img>
        </div>
      </div>
      <div className={`absolute bottom-0 left-0 w-full bg-[#036EB7] opacity-90 text-white text-left transition-all duration-500 ease-in-out overflow-hidden ${isHovered ? 'h-full p-4' : 'h-0 p-0'}`}>
        <div className='flex flex-col justify-between h-full'>
          <div className='flex-grow'>
            <div className='flex flex-wrap w-full justify-between mt-[0.1rem]'>
              <p className='text-[#FFFFFF] 1440xl:text-[1rem] text-[0.58vw] font-noto font-light'>{overlayContent.title}</p>
              <img src="arrow.png" className={`w-[15px] h-[7px] transform ${isArrowRotated ? 'rotate-180' : 'rotate-0'} transition-transform duration-500 ease-in-out mr-[10px] mt-[0.5rem]`}></img>
            </div>
            <p className={`mt-[0.5rem] text-[#FFFFFF] 1440xl:text-[0.58rem] text-[0.58vw] font-noto font-thin transition-opacity duration-500 ease-in-out ${isHovered ? 'opacity-100 delay-500' : 'opacity-0'}`}>{overlayContent.text}</p>
          </div>
          <p className='1440xl:text-[0.61rem] text-[0.61vw] font-noto font-light'>{overlayContent.texten}</p>
        </div>
      </div>
    </div>
  );
};


function index() {
  const [hoveredColor, setHoveredColor] = useState(null);
  const [hideTimeout, setHideTimeout] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    return () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
    };
  }, [hideTimeout]);

  const handleMouseEnter = (id) => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }
    setHoveredColor(id);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredColor(null);
      setIsHovered(false);
    }, 1000);
    setHideTimeout(timeout);
  };

  const [activeDescription, setActiveDescription] = useState(null);
  const [activePosition, setActivePosition] = useState({ top: 0, left: 0 });

  const colors = [
    { id: 1, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(0,109,255,0.8)]', descriptionTitle: '蓝色氢气', text: '蓝色氢气主要由天然气生产，采用一种称为蒸汽重整的工艺，将天然气和热水以蒸汽形式聚集在一起。产出的是氢气，但二氧化碳也是副产品。因此，蓝氢的定义包括使用碳捕获与储存捕捉和储存这些碳。蓝氢有时被称为“低碳氢”，因为蒸汽重整过程实际上并没有避免温室气体的产生。 ', gradientBG: 'linear-gradient(135deg, #FFFFFF 0%, #F8FBFF 9%, rgba(231,241,255,0.98) 22%, rgba(203,225,255,0.96) 36%, rgba(163,202,255,0.93) 52%, rgba(113,173,255,0.89) 69%, rgba(52,138,255,0.84) 86%, rgba(0,109,255,0.8) 100%)' },
    { id: 2, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(0,211,186,0.8)]', descriptionTitle: '绿松石氢', text: '这是氢颜色图表中的一个新条目，其生产还需要在规模上得到证明。绿松石氢是使用一种称为甲烷热解的过程来产生氢气和固体碳。在未来，绿松石氢可能会被视为一种低排放的氢，这取决于以可再生能源为动力的热过程以及永久储存或使用的碳。 ', gradientBG: 'linear-gradient( 135deg, #FFFFFF 0%, rgba(0,211,186,0.8) 100%)' },
    { id: 3, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(56,185,46,0.8)]', descriptionTitle: '绿氢', text: '绿色氢是通过使用太阳能或风能等剩余可再生能源产生的清洁电力电解水制成的。电解槽利用电化学反应将水分解为氢和氧，在此过程中二氧化碳排放量为零。\n 由于生产成本高，绿氢目前只占全部氢气的一小部分。正如风力发电的价格下降一样，随着绿色氢变得更加普遍，它的价格也会下降。', gradientBG: 'linear-gradient( 130deg, #FFFFFF 0%, rgba(56,185,46,0.8) 100%)' },
    { id: 4, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(244,204,0,0.8)]', descriptionTitle: '黄色氢', text: '黄氢是一个相对较新的术语，指的是通过电解使用太阳能. ', gradientBG: 'linear-gradient( 151deg, #FFFFFF 0%, rgba(244,204,0,0.8) 100%' },
    { id: 5, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(220,99,186,0.8)]', descriptionTitle: '粉红色氢气', text: '粉红色的氢气是通过核能供电的电解产生的。核产生的氢也可以被称为紫氢或红氢。此外，核反应堆产生的非常高的温度可以用于其他氢气生产，通过产生蒸汽进行更有效的电解或基于化石气体的蒸汽甲烷重整。    ', gradientBG: 'linear-gradient( 135deg, #FFFFFF 0%, rgba(220,99,186,0.8) 100%)' },
    { id: 6, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(180,180,181,0.8)]', descriptionTitle: '灰色氢气', text: '目前，这是最常见的制氢方式。灰氢是从天然气或甲烷中产生的，使用蒸汽甲烷重整，但没有捕获温室气体制作过程中。灰氢本质上与蓝氢相同，但没有使用碳捕获和存储。 ', gradientBG: '#F7F7F7' },
    { id: 7, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(35,24,21,0.8)]', descriptionTitle: '黑色氢气', text: '在制氢过程中使用黑煤或褐煤（褐煤），这些黑色和棕色的氢气在氢气光谱中与绿色氢气完全相反，对环境的破坏最大。只是为了混淆视听，通过“气化”过程从化石燃料中制得的任何氢气有时都被交替称为黑色或棕色氢气。 ', gradientBG: 'linear-gradient( 135deg, #FFFFFF 0%, rgba(150,150,150,0.8) 100%)' },
    { id: 8, gradient: 'from-[rgba(238,238,239,0.8)] to-[rgba(170,78,36,0.8)]', descriptionTitle: '棕色氢气', text: '日本和澳大利亚最近宣布了一项新的褐煤制氢项目。该项目将使用澳大利亚的褐煤生产液化氢，然后将其运往日本用于低排放用途。 ', gradientBG: 'linear-gradient( 127deg, #FFFFFF 0%, rgba(170,78,36,0.8) 100%)' },
  ];
  const defaultContent1 = {
    image: 'https://www.freeimg.cn/i/2024/07/17/669765edb4fcd.png',
    // image: 'p1.png',
    text: '全球气候变暖',
    texten: 'Global warming'
  };
  const defaultContent2 = {
    image: 'https://www.freeimg.cn/i/2024/07/17/669765edcd567.png',
    // image: 'p2.png',
    text: '病虫害增加',
    texten: 'Increased pests and diseases'
  };
  const defaultContent3 = {
    image: 'https://www.freeimg.cn/i/2024/07/17/669765edc54e1.png',
    // image: 'p3.png',
    text: '海平面上升',
    texten: 'Sea-level rise'
  };
  const defaultContent4 = {
    image: 'https://www.freeimg.cn/i/2024/07/17/669765edb599f.png',
    // image: 'p4.png',
    text: '海洋风暴增多',
    texten: 'Increase in oceanic storms'
  };
  const defaultContent5 = {
    image: 'https://www.freeimg.cn/i/2024/07/17/669765edc39e9.png',
    // image: 'p5.png',
    text: '土地沙漠化',
    texten: 'Desertification'
  };

  const overlayContent1 = {
    title: '全球气候变暖',
    text: '自20世纪以来，全球平均温度上升约1.1°C，极端天气事件频发，海平面上升20厘米，冰川融化速度加快，均显示全球气候正经历显著变暖。',
    texten: 'Global warming'
  };
  const overlayContent2 = {
    title: '病虫害增加',
    text: '20世纪至今，全球海平面因冰川融化和海水热胀冷缩效应已上升约20厘米，预计到2100年，若温室气体排放未减，海平面上升范围或将达0.26至0.77米，危及数亿人口居住的低洼沿海区域。',
    texten: 'Increased pests and diseases'
  };
  const overlayContent3 = {
    title: '海平面上升',
    text: '近十年，全球农作物病虫害发生面积显著增长，平均每年影响4.8051亿公顷，比前十年增加20.82%，导致每年损失约1.2亿至1.3亿吨粮食。气候变化促使害虫扩散，如秋粘虫与果蝇侵袭更多作物，沙漠蝗虫改变迁徙路线，威胁食品安全。',
    texten: 'Sea-level rise'
  };
  const overlayContent4 = {
    title: '海洋风暴增多',
    text: '过去几十年中，北大西洋强烈飓风数量增加了约33%，海洋表面温度上升为风暴强度与频率增加提供动力，预计气候变化将继续加剧全球海洋风暴活动，威胁沿海安全与经济稳定。',
    texten: 'Increase in oceanic storms'
  };
  const overlayContent5 = {
    title: '土地沙漠化',
    text: '全球每年约有1200万公顷土地退化为沙漠，相当于每分钟丧失23公顷。受气候变化和人为活动影响，过去半个世纪，全球土地沙漠化影响了超过1/3的陆地面积，威胁生态平衡与人类生存环境',
    texten: 'Desertification'
  };

  return (
    // <div className='grid justify-items-center h-full  '>
    <div className='flex flex-col items-center'>
      <Header />
      <div className="relative w-full h-[50vw] 1440xl:h-[732px] overflow-hidden  mt-[5vw] ">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="home1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="text-white max-w-[1440px] w-full mx-auto px-[10%]">
            <img className="block w-[60vw] 1440xl:w-[864px] mx-auto" src="indextitle.png" alt="描述性文字"></img>
            <p className="text-[1.3vw] 1440xl:text-[1.3rem] font-noto font-regular mt-[100px] 1440xl:mt-[7vw]">深耕等离子化工领域<br /> 倡导零碳排放的新材料新技术</p>
          </div>
        </div>
      </div>
      
      <div className='flex pt-[12vw] 1440xl:pt-[175px] font-noto w-full mx-auto flex-col items-center'>
        <div className='max-w-screen-1440xl w-full flex flex-col items-center'>
          <div className='flex w-[62vw] 1440xl:w-[892px] 1440xl:h-[93px] h-[6vw]'>
            <div className='border-r-2 border-black w-[25vw] 1440xl:w-[403px] '>
              <p className='text-[#040000] text-left text-[2vw] 1440xl:text-[29px] font-noto font-regular tracking-[0.1em]'>我们的地球</p>
              <div className='w-[22px] border-2 border-[#036EB6] mt-4'></div>
            </div>
            <p className='text-[#040000]  w-[75vw] 1440xl:w-[1080px] ml-20 text-left text-[1vw] 1440xl:text-[18px] font-noto font-light tracking-[0.078em]'>
              人们普遍认为，人类对自然资源的过度开发引发了一些自然灾害，这其中就有由于二氧化碳的无限制排放而产生的。二氧化碳加强了温室效应，而温室效应又给人类带来了无法想象的后果……
            </p>
          </div>
        </div>
      </div>
    
      <section className='h-[75vw] 1440xl:h-[1086px] bg-[url("indexP1.png")] bg-no-repeat bg-contain w-full flex flex-col items-center bg-[#FFFFFF] max-w-[1440px]'>
        <div className='w-full max-w-[1440px] flex flex-col items-center'>
          <div className="flex w-[62%] mt-[8.6vw] 1440xl:mt-[124px]">
            <div className="flex w-full mb-[13rem] gap-4">
              <Box defaultContent={defaultContent1} overlayContent={overlayContent1} />
              <Box defaultContent={defaultContent2} overlayContent={overlayContent2} />
              <Box defaultContent={defaultContent3} overlayContent={overlayContent3} />
              <Box defaultContent={defaultContent4} overlayContent={overlayContent4} />
              <Box defaultContent={defaultContent5} overlayContent={overlayContent5} />
            </div>
          </div>
        </div>
        <div className='w-full max-w-[1440px] flex flex-col items-center'>
          <div className="text-white font-noto-sans-sc w-[62%] flex 1440xl:h-[227px] h-[16vw] 1440xl:mt-[210px]  mt-[5vw] 1440xl:mb-[200px] mb-[13vw]" >
            <div className='border-r-2 border-white w-[25%]'>
              <p className="1440xl:text-[29px] text-[2vw] font-noto font-regular tracking-[0.1em] text-left 1440xl:w-[353px] w-[25vw]">我们的承诺</p>
              <div className='1440xl:w-[22px] w-[2vw]  border-2 border-[#036EB6] mt-4'></div>
            </div>
            <div className='text-white w-3/4 ml-20 text-left 1440xl:text-[18px] text-[1.2vw] font-noto font-light tracking-[0.078em]'>
              <p className="">中国在巴黎气候大会上的承诺是中国作为全球最大的发展中国家积极应对气候变化的具体体现。
              </p>
              <p className="1440xl:mt-[40px] mt-[2.7vw]">在全球限制气候变暖幅度在2摄氏度以内的共识下，中国政府承诺到2030年，将二氧化碳排放强度比2005年<span className='font-noto font-bold '>下降20%左右</span>，单位国内生产总值二氧化碳排放比2005年<span className='font-noto font-bold '>下降60%-65%</span>，非化石能源占一次能源消费的比重达到20%左右，森林蓄积量比2005年<span className='font-noto font-bold '>增加45亿立方米</span>左右
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className='1440xl:h-[42rem] h-[42vw] 1440xl:mt-[-5rem] mt-[-5vw] 1440xl:pt-[5rem] pt-[5vw] 1440xl:pb-[4rem] pb-[4vw]  bg-gradient-to-br from-[#ffffff] to-[#E0E0E0] grid place-items-center rounded-[3rem] w-[90%] max-w-[1296px]'>
        <div className='w-full flex flex-col items-center'>
          <div className='flex w-[62vw] 1440xl:w-[892px] 1440xl:h-[93px] h-[6.4vw]'>
            <div className='border-r-2 border-black w-[18vw] 1440xl:w-[259px]'>
              <p className='text-[#040000] 1440xl:text-[29px] text-[2vw] text-left font-noto font-regular tracking-[0.1em] ' >氢的色谱</p>
              <div className='1440xl:w-[22px] w-[1.5vw] border-2 border-[#036EB6] mt-4 '></div>
            </div>
            <p className='text-[#040000] w-3/4 ml-20 text-left 1440xl:text-[18px] text-[1.2vw] font-noto font-light tracking-[0.078em]'>氢的色谱分析是一种分离检测技术，利用气体（通常是氢气）作为流动相携带样品通过装有固定相的色谱柱。不同组分因与固定相相互作用力的差异，在柱中移动速度不同，从而被分离。</p>
          </div>
          <div className='flex justify-between mb-[28rem] mt-[6rem] w-[68%] h-[2rem] py-[0.5rem] items-center'>
            {colors.slice(0, 8).map((color, index) => {
              // 计算圆圈和线段的宽度（固定为8个圆圈）
              const circleDiameter = 985 / 15 - 20;

              return (
                <React.Fragment key={color.id}>
                  <div
                    className={`1440xl:w-[44.8px] 1440xl:h-[44.8px] relative bg-gradient-to-br ${color.gradient} rounded-full transition-opacity duration-300 ease-in-out shadow-[1rem_1rem_0px_0px_rgba(0,0,0,0.1)] ${hoveredColor === color.id ? 'opacity-100' : 'opacity-50'} w-[calc(4.5vw-20px)] h-[calc(4.5vw-20px)]
                    1440xl:w-[44px] 1440xl:h-[44px]`}
                    style={{ boxShadow: '0.4rem 0.4rem 0.3rem 0px rgba(0,0,0,0.2)' }}
                    onMouseEnter={() => handleMouseEnter(color.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* 隐藏的 H2 字样 */}
                    <span
                      className={`absolute inset-0 flex items-center justify-center text-white 1440xl:text-[1.125rem] text-[1.25vw] font-bold transition-opacity duration-300 ease-in-out ${hoveredColor === color.id ? 'opacity-100' : 'opacity-0'}`}
                    >
                      H<span className='1440xl:text-[10px] text-[1.14vw]  bottom-0' style={{ paddingTop: '6px' }}>2</span>
                    </span>

                    {hoveredColor === color.id && (
                      <div className="px-[1vw] py-[1vw]  1440xl:px-[1rem] 1440xl:py-[1rem] absolute flex-col mt-[4.4vw] 1440xl:mt-[4rem] ml-[-0.5rem] w-[15.5vw] 1440xl:w-[14rem] h-auto bg-cover bg-center rounded-[0.5rem] shadow-[1rem_1rem_0px_0px_rgba(0,0,0,0.2)] flex text-left"
                        style={{ background: color.gradientBG, boxShadow: '1rem 1rem 0.5rem 0px rgba(0,0,0,0.2)' }}>
                        <p className='1440xl:text-[15.99px] text-[1.11vw]  tracking-[0.1em] font-noto font-regular'>{color.descriptionTitle}</p>
                        <p className='text-[#323232] 1440xl:text-[12.79px] text-[0.8vw] tracking-[0.1em] 1440xl:leading-5 leading-[1.3vw] font-noto font-regular 1440xl:mt-[0.5rem] mt-[0.55vw]'>
                          {color.text.split('\n').map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* 添加灰色小线段，除最后一个颜色块外 */}
                  {index < 7 && (
                    <div className="bg-gray-300" style={{ width: `${circleDiameter}px`, height: '0.2rem' }}></div>
                  )}
                </React.Fragment>
              );
            })}

            {hoveredColor === null && (
              <div className="px-[1vw] py-[1vw]  1440xl:px-[1rem] 1440xl:py-[1rem] absolute flex-col 1440xl:mt-[21rem] mt-[22vw] 1440xl:ml-[7rem] ml-[8vw] w-[15.5vw] 1440xl:w-[14rem]  h-auto rounded-[0.5rem] flex text-left"
                style={{ background: 'linear-gradient(135deg, #FFFFFF 0%, rgba(0,211,186,0.8) 100%)', boxShadow: '1rem 1rem 0.5rem 0px rgba(0,0,0,0.2)' }}>
                <p className='1440xl:text-[15.99px] text-[1.11vw] tracking-[0.1em] font-noto font-regular'>绿松石氢</p>
                <p className='text-[#323232] 1440xl:text-[12.79px] text-[0.8vw] tracking-[0.1em] 1440xl:leading-5 leading-[1.3vw] font-noto font-regular 1440xl:mt-[0.5rem] mt-[0.55vw]'>
                  这是氢颜色图表中的一个新条目，其生产还需要在规模上得到证明。绿松石氢是使用一种称为甲烷热解的过程来产生氢气和固体碳。在未来，绿松石氢可能会被视为一种低排放的氢，这取决于以可再生能源为动力的热过程以及永久储存或使用的碳。
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bg-[url("66976be158743.png")]  bg-no-repeat bg-center bg-cover 1440xl:h-[38rem] h-[38vw] py-[210px]  max-w-[1440px] w-full flex flex-col items-center'>
        <div className='max-w-[1440px] w-full flex flex-col items-center'>
          <div className='flex w-[62%] 1440xl:h-[93px] h-[6.4vw]'>
            <div className='border-r-2  border-black w-[28%] 1440xl:text-[29px] text-[2vw] '>
              <p className='text-[#040000]  text-left font-noto font-regular tracking-[0.1em]' >氢能源的</p>
              <p className='text-[#040000]  text-left font-noto font-regular tracking-[0.1em]'>未来</p>
              <div className='w-[22px] border-2 border-[#036EB6] mt-4'></div>
            </div>
            <p className='text-[#040000] w-3/4 ml-20 text-left 1440xl:text-[18px] text-[1.2vw]  font-noto font-light tracking-[0.078em]'>将来，一些氢颜色的重要性可能会减弱，而其他颜色会变得更亮。
              可以肯定的是氢彩虹将在到达“净零”碳排放，因为我们减少了对化石燃料的依赖，转而寻求绿色替代能源为我们的家庭、商业和交通提供动力。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>




  )
}

export default index
