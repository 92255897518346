import React from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
// import { css as css1 } from '../assets/NotoSansCJKsc-Thin.otf?subsets';
function news() {
  return (
    <div className=''>
      <Header />
      <div className="relative w-full  h-[30vw] 1440xl:h-[432px] overflow-hidden mt-[6.2vw] 1440xl:mt-[5.5rem] ">
        <img className='w-full object-cover h-full' src="news2.png">
        </img>
        <div className="absolute inset-0  flex items-center justify-center ">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.8rem] 1440xl:tracking-[0.8vw] text-white text-[4.1vw] 1440xl:text-[3.3rem]  '>新闻动态</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  1440xl:mt-[28.8px] mt-[2.4vw] text-[2vw] 1440xl:text-[1.84rem]">News</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-[16vw] px-[11vw] h-[10vw] mb-[276px] 1440xl:py-[16rem] 1440xl:px-[11rem] 1440xl:h-auto">
        <div className="flex bg-[#F7F7F7] h-[32vw] py-[2vw] px-[1.68vw] mx-auto 1440xl:h-[441px] 1440xl:py-[2rem] 1440xl:px-[1.68rem] 1440xl:w-full 1440xl:max-w-[1078px]">
          <div className="w-[27vw] h-[27vw] 1440xl:w-[389px] 1440xl:h-[389px]">
            <img src="news1.png" className="w-full h-full object-cover" />
          </div>
          <div className="text-left ml-[4.3vw] w-[36.2vw] flex flex-col justify-between h-full 1440xl:ml-[4.3rem] 1440xl:w-[521px]">
            <p className="text-left pb-[2vw] text-[2.25vw] font-noto font-bold 1440xl:pb-[2rem] 1440xl:text-[2.25rem]">恭喜！第一阶段成功！</p>
            <div className="w-full h-[0.1vw] mb-[1vw] bg-[#231815] border-t 1440xl:h-[0.1rem] 1440xl:mb-[1rem]"></div>
            <p className="h-[22vw] text-[1.37vw] font-noto font-thin 1440xl:h-[22rem] 1440xl:text-[1.37rem]">2024年9月30日，我们的实验室开发阶段将告一段落，并立即进入开发的下一阶段。在实验室阶段，我们完美地达到了预期的结果，为我们的研发团队喝彩！</p>
            <p className="text-[1.37vw] text-right font-noto font-thin 1440xl:text-[1.37rem]">MORE{'>'}</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>


  )
}

export default news
