import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className='fixed w-full top-0 bg-white z-50 flex-wrap'>

      <nav className="font-noto-regular text-[#5F5F5F] flex items-center justify-between px-0 py-0 border-gray-200 mx-auto fixed-nav max-w-[1440px] w-full " >
        <div className="flex items-center py-[2vw] 1440xl:py-[1rem] ml-8">
          <svg className="w-[8.82vw] h-[2.71vw] 1440xl:w-[147px] 1440xl:h-[64px] " xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 68.069 21.313">
            <g id="组_3377" data-name="�? 3377" transform="translate(-86.606 -91.116)">
              <g id="组_3369" data-name="�? 3369">
                <path id="路径_9610" data-name="路径 9610" d="M109.3,92.624a3.945,3.945,0,0,1,3.946,3.934v10.43a3.945,3.945,0,0,1-3.946,3.933,3.882,3.882,0,0,1-2.761-1.156l-4.794-4.794-1.067,1.066,4.795,4.795a5.383,5.383,0,0,0,3.827,1.6,5.453,5.453,0,0,0,5.453-5.441V96.558a5.453,5.453,0,0,0-5.453-5.442h0a5.387,5.387,0,0,0-3.827,1.6l-7.993,7.993-1.066,1.066,1.066,1.067,1.066-1.067,7.993-7.992a3.887,3.887,0,0,1,2.761-1.156m0,15.281a.918.918,0,0,0,.93-.917V96.558a.918.918,0,0,0-.93-.918.866.866,0,0,0-.629.272l-5.86,5.86,5.86,5.861a.866.866,0,0,0,.629.272m-4.357-6.132,3.779-3.779v7.558l-3.779-3.779Z" fill="#036eb7" />
                <path id="路径_9611" data-name="路径 9611" d="M92.06,110.921a3.944,3.944,0,0,1-3.946-3.933V96.558a3.945,3.945,0,0,1,3.946-3.934,3.886,3.886,0,0,1,2.76,1.156l4.795,4.794,1.066-1.066-4.795-4.794a5.383,5.383,0,0,0-3.826-1.6,5.454,5.454,0,0,0-5.454,5.442v10.43a5.453,5.453,0,0,0,5.454,5.441h0a5.382,5.382,0,0,0,3.826-1.6l7.993-7.993,1.066-1.066-1.066-1.066-1.066,1.066-7.993,7.992a3.882,3.882,0,0,1-2.76,1.156m0-15.281a.918.918,0,0,0-.931.918v10.43a.918.918,0,0,0,.931.917.865.865,0,0,0,.628-.272l5.86-5.86-5.86-5.861a.865.865,0,0,0-.628-.272m4.356,6.133-3.779,3.779V97.994l3.779,3.779Z" fill="#036eb7" />
              </g>
              <path id="路径_9612" data-name="路径 9612" d="M125.795,94.519l-2.843,2.656-4.144-4.762H127.1Z" fill="#00a63c" />
              <g id="组_3370" data-name="�? 3370">
                <path id="矩形_686" data-name="矩形 686" d="M0,0H1.9a0,0,0,0,1,0,0V8.559a0,0,0,0,1,0,0h0A1.9,1.9,0,0,1,0,6.658V0A0,0,0,0,1,0,0Z" transform="translate(129.948 92.413)" fill="#231815" />
                <rect id="矩形_687" data-name="矩形 687" width="1.902" height="8.559" transform="translate(136.606 92.413)" fill="#231815" />
                <path id="矩形_688" data-name="矩形 688" d="M0,0H1.9a0,0,0,0,1,0,0V6.658A1.9,1.9,0,0,1,0,8.559H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(138.508 99.062) rotate(90)" fill="#231815" />
              </g>
              <g id="组_3371" data-name="�? 3371">
                <rect id="矩形_689" data-name="矩形 689" width="1.902" height="8.559" transform="translate(117.274 92.413)" fill="#231815" />
                <rect id="矩形_690" data-name="矩形 690" width="1.902" height="8.559" transform="translate(127.096 92.413)" fill="#231815" />
                <rect id="矩形_691" data-name="矩形 691" width="1.902" height="7.501" transform="translate(117.831 93.758) rotate(-45)" fill="#231815" />
                <rect id="矩形_692" data-name="矩形 692" width="1.902" height="7.501" transform="translate(123.135 99.062) rotate(-135)" fill="#231815" />
              </g>
              <g id="组_3376" data-name="�? 3376">
                <g id="组_3372" data-name="�? 3372">
                  <rect id="矩形_693" data-name="矩形 693" width="1.902" height="8.559" transform="translate(117.274 102.895)" fill="#231815" />
                  <rect id="矩形_694" data-name="矩形 694" width="1.902" height="8.559" transform="translate(123.932 102.895)" fill="#231815" />
                  <rect id="矩形_695" data-name="矩形 695" width="1.902" height="8.559" transform="translate(125.834 106.223) rotate(90)" fill="#231815" />
                </g>
                <g id="组_3373" data-name="�? 3373">
                  <path id="矩形_696" data-name="矩形 696" d="M0,0H1.9a0,0,0,0,1,0,0V8.559a0,0,0,0,1,0,0h0A1.9,1.9,0,0,1,0,6.658V0A0,0,0,0,1,0,0Z" transform="translate(136.295 102.895)" fill="#231815" />
                  <rect id="矩形_697" data-name="矩形 697" width="1.902" height="8.559" transform="translate(139.624 102.895)" fill="#231815" />
                  <path id="矩形_698" data-name="矩形 698" d="M0,0H1.9a0,0,0,0,1,0,0V6.658A1.9,1.9,0,0,1,0,8.559H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(142.953 102.895)" fill="#231815" />
                  <path id="矩形_699" data-name="矩形 699" d="M0,0H0A1.9,1.9,0,0,1,1.9,1.9V6.658A1.9,1.9,0,0,1,0,8.559H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(144.854 109.552) rotate(90)" fill="#231815" />
                </g>
                <g id="组_3374" data-name="�? 3374">
                  <path id="矩形_700" data-name="矩形 700" d="M1.9,0h0a0,0,0,0,1,0,0V8.559a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.9A1.9,1.9,0,0,1,1.9,0Z" transform="translate(126.785 102.895)" fill="#231815" />
                  <path id="矩形_701" data-name="矩形 701" d="M0,0H0A1.9,1.9,0,0,1,1.9,1.9V8.559a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(133.442 102.895)" fill="#231815" />
                  <path id="矩形_702" data-name="矩形 702" d="M1.9,0h0a0,0,0,0,1,0,0V8.559a0,0,0,0,1,0,0h0A1.9,1.9,0,0,1,0,6.658V1.9A1.9,1.9,0,0,1,1.9,0Z" transform="translate(135.344 102.895) rotate(90)" fill="#231815" />
                  <rect id="矩形_703" data-name="矩形 703" width="1.902" height="8.559" transform="translate(135.344 106.223) rotate(90)" fill="#231815" />
                </g>
                <g id="组_3375" data-name="�? 3375">
                  <rect id="矩形_704" data-name="矩形 704" width="1.902" height="8.559" transform="translate(145.805 102.895)" fill="#231815" />
                  <path id="路径_9613" data-name="路径 9613" d="M151.977,111.445h2.689l-4.271-4.271,4.28-4.28h-2.689l-2.935,2.935-1.345,1.345,1.345,1.345Z" fill="#231815" />
                </g>
              </g>
            </g>
          </svg>
        </div>     
        <div className="w-[55vw] 1440xl:w-[800px] text-left ml-7">
        <p className=" text-[1.52vw] 1440xl:text-[22px]  font-noto font-regular text-black">杭州慕皓新能源技术有限公司</p>
        <p className=" text-[0.76vw] 1440xl:text-[11px]  font-noto font-regular text-black"> Hangzhou MUHAWK Clean Energy Technology Co., Ltd.</p>
        </div>
        <div className="flex justify-end items-center text-base w-full  flex-grow mr-10 h-[2vw]" >
          <Link to="/index" className="text-[1.38vw] 1440xl:text-[1.267rem] hover:text-white  hover:border-[#036EB7] hover:bg-[#036EB7]  py-[2.2vw] 1440xl:py-10 px-[1.6vw] 1440xl:px-6">主页</Link>
          <Link to="/product" className="text-[1.38vw] 1440xl:text-[1.267rem] hover:text-white hover:border-[#036EB7] hover:bg-[#036EB7] py-[2.2vw] 1440xl:py-10  px-[1.6vw] 1440xl:px-6">产品与技术</Link>
          <Link to="/teaminfo" className="text-[1.38vw] 1440xl:text-[1.267rem] hover:text-white hover:border-[#036EB7] hover:bg-[#036EB7]  py-[2.2vw] 1440xl:py-10  px-[1.6vw] 1440xl:px-6">关于我们</Link>
          <Link to="/news" className="text-[1.38vw] 1440xl:text-[1.267rem] hover:text-white hover:border-[#036EB7] hover:bg-[#036EB7]  py-[2.2vw] 1440xl:py-10  px-[1.6vw] 1440xl:px-6">新闻动态</Link>
          <Link to="/contact" className="text-[1.38vw] 1440xl:text-[1.267rem] hover:text-white hover:border-[#036EB7] hover:bg-[#036EB7]  py-[2.2vw] 1440xl:py-10  px-[1.6vw] 1440xl:px-6">联系我们</Link>
        </div>
      </nav>
      <section className="flex mx-auto">
        <div className="w-1/4 h-2 bg-[#00A63C]"></div>
        <div className="w-3/4 h-2 bg-[#036EB7]"></div>
      </section>
    </div>

  )
}

export default Header

