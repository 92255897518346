import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';
import { useCallback } from 'react';
// import { HoverContext } from '../context/HoverContext';
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'

function product() {
  return (
    <div className='font-noto-sans-sc flex flex-col items-center'>
      <Header />

      <div className="relative w-full h-[30vw] 1440xl:h-[432px] overflow-hidden mt-[6.2vw] 1440xl:mt-[5.5rem] ">
        <div className="absolute top-0 left-0 w-full h-[200%] overflow-hidden">
          <video
            className="absolute top-[-25%] left-0 w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="product.mp4" type="video/mp4" />
            Your browser does not support the video tag.s
          </video>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white  font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.8rem] 1440xl:tracking-[0.8vw]  text-white text-[4.1vw] 1440xl:text-[3.3rem]  '>产品与技术</h1>
            <p className="font-noto font-regular tracking-[0.14rem]  1440xl:mt-[28.8px] mt-[2.4vw] text-[2vw] 1440xl:text-[1.84rem] ">Products and Technology</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center h-auto pt-[10vw] 1440xl:pt-[175px] font-noto font-regular w-full max-w-[1440px] mx-[3rem]">
        <div className='text-left w-full mx-[10vw] 1440xl:mx-[10rem]'>
          <p className='text-[3vw] 2xl:text-[3rem] mb-[1.375rem] font-noto font-bold '>产品介绍</p>
          <p className='text-[1.5vw] 2xl:text-[1.5rem] font-noto font-thin'>
            天然气、生物燃料和其他碳氢化合物燃料的可用于氢的生产。作为第四态物质，等离子体技术可以与风，光等绿色能源结合用于氢气的生产。等离子体条件（高温和高电离度）可用于加速热力学上可行的化学反应，而无需催化剂或提供吸热式改质所需的能量。
            <br />
            <br />
            等离子体可带来许多优势：
            <br />
            经济上具有吸引力的小规模的氢生成、高转化率、无须催化剂敏感性和无CO₂生成问题、紧凑和轻量化（由于功率密度高）、最低成本、快速响应时间（几分之一秒）
          </p>
        </div>
      </div>
      <div class="w-full max-w-screen-xl p-4 mt-[5rem] flex justify-center">
        <img src="gongchang.png" alt="示例图片" class="w-4/5 max-w-full max-h-screen object-contain" />
      </div>
      {/* <div className='ml-[6vw] grid place-items-center h-screen'>
        <div className="relative w-[90vw] mx-auto h-[40vw] 2xl:h-[40rem] pt-[5rem] pb-[37rem]">
          <img className="w-[1vw] absolute top-[10.5vw] left-[29.6vw] 2xl:w-[1rem] 2xl:top-[10.5rem] 2xl:left-[29.6rem]" src="YX.png" alt="天然气图片1" />
          <img className="w-[1vw] absolute top-[18.7vw] left-[42.44vw] 2xl:w-[1rem] 2xl:top-[18.7rem] 2xl:left-[42.44rem] " src="YX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem] 2xl:top-[27.5rem] 2xl:left-[57rem] w-[1vw] absolute top-[27.5vw] left-[57vw]" src="YX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem]  2xl:top-[20rem] 2xl:left-[56rem] w-[1vw] absolute top-[20vw] left-[56vw]" src="ZX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem]  2xl:top-[18rem] 2xl:left-[31rem] w-[1vw] absolute top-[18vw] left-[31vw]" src="ZX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem]  2xl:top-[27.5rem] 2xl:left-[44.5rem] w-[1vw] absolute top-[27.5vw] left-[44.5vw]" src="ZX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem]  2xl:top-[36.4rem] 2xl:left-[29.8rem] w-[1vw] absolute top-[36.4vw] left-[29.8vw]" src="ZX.png" alt="天然气图片1" />
          <img className="2xl:w-[1rem]  2xl:top-[20rem] 2xl:left-[70rem] w-[1vw] absolute top-[20vw] left-[70vw]" src="ZS.png" alt="天然气图片1" />
          <img className="2xl:w-[33rem]  2xl:top-[0rem] 2xl:left-[0rem] w-[33vw] absolute top-[0vw] left-[0vw]" src="L1.png" alt="天然气图片1" />
          <img className="2xl:w-[11.4rem]  2xl:top-[3rem] 2xl:left-[18rem] w-[11.4vw] absolute top-[3vw] left-[18vw]" src="chuan.png" alt="天然气图片1" />
          <img className="2xl:w-[4rem]  2xl:top-[11rem] 2xl:left-[7rem] w-[4vw] absolute top-[11vw] left-[7vw]" src="yun.png" alt="天然气图片1" />
          <img className="2xl:w-[20.6rem]  2xl:top-[8.76rem] 2xl:left-[26.8rem] w-[20.6vw] absolute top-[8.76vw] left-[26.8vw]" src="L21.png" alt="天然气图片2" />
          <img className="2xl:w-[20.6rem]  2xl:top-[8.76rem] 2xl:left-[53rem] w-[20.6vw] absolute top-[8.76vw] left-[53vw]" src="L22.png" alt="天然气图片2" />
          <img className="2xl:w-[20.6rem]  2xl:top-[16rem] 2xl:left-[15rem] w-[20.6vw] absolute top-[16vw] left-[15vw]" src="L31.png" alt="天然气图片1" />
          <img className="2xl:w-[20.6rem]  2xl:top-[17rem] 2xl:left-[41rem] w-[20.6vw] absolute top-[17vw] left-[41vw]" src="L32.png" alt="天然气图片2" />
          <img className="2xl:w-[20.6rem]  2xl:top-[14.5rem] 2xl:left-[65rem] w-[20.6vw] absolute top-[14.5vw] left-[65vw]" src="L33.png" alt="天然气图片2" />
          <img className="2xl:w-[20.6rem]  2xl:top-[24.5rem] 2xl:left-[27.4rem] w-[20.6vw] absolute top-[24.5vw] left-[27.4vw]" src="L41.png" alt="天然气图片1" />
          <img className="2xl:w-[20.6rem]  2xl:top-[25.5rem] 2xl:left-[53rem] w-[20.6vw] absolute top-[25.5vw] left-[53vw]" src="L42.png" alt="天然气图片1" />
          <img className="2xl:w-[20.6rem]  2xl:top-[35rem] 2xl:left-[14rem] w-[20.6vw] absolute top-[35vw] left-[14vw]" src="L51.png" alt="天然气图片2" />
          <img className="2xl:w-[20.6rem]  2xl:top-[8.76rem] 2xl:left-[53rem] w-[20.6vw] absolute top-[8.76vw] left-[53vw]" src="L22.png" alt="天然气图片2" />
        </div>
      </div> */}
      <div className="grid place-items-center mt-[8vw] 1440xl:mt-[8rem]">
        <div className="flex w-[78vw] text-[2vw] mt-[5vw] mb-[10vw] 1440xl:w-[78rem] 1440xl:text-[2rem] 1440xl:mt-[5rem] 1440xl:mb-[10rem]">
          <div className="flex 1440xl:w-[78rem] 1440xl:px-[4rem]">
            <div className="w-[18vw] h-[40vw] bg-[#F7F7F7] rounded-[1rem] flex flex-col items-center pt-[4.56vw] 1440xl:w-[18rem] 1440xl:h-[643px] 1440xl:py-[4.56rem]">
              <svg xmlns="http://www.w3.org/2000/svg" width="6vw" height="6.8vw" viewBox="0 0 31.168 34.38" className="1440xl:w-[6rem] 1440xl:h-[6.8rem]">
                <g id="组_3260" data-name="组 3260" transform="translate(-833.512 -1002.711)">
                  <path id="路径_9411" data-name="路径 9411" d="M854.444,1007.354a15.085,15.085,0,0,1,8.4,20.282" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9412" data-name="路径 9412" d="M842.909,1035.2a15.113,15.113,0,0,0,12.374,0" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9413" data-name="路径 9413" d="M835.348,1027.635a15.084,15.084,0,0,1,8.4-20.281" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9414" data-name="路径 9414" d="M862,1034.354a3.178,3.178,0,0,1-4.5,0,3.022,3.022,0,0,1-.222-.247,3.179,3.179,0,0,1,4.471-4.471c.085.07.167.144.247.223A3.179,3.179,0,0,1,862,1034.354Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <circle id="椭圆_88" data-name="椭圆 88" cx="3.179" cy="3.179" r="3.179" transform="translate(835.26 1028.928)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9415" data-name="路径 9415" d="M852.258,1006.711a3.176,3.176,0,1,1-.914-2.57A3.158,3.158,0,0,1,852.258,1006.711Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <text id="H" transform="translate(847.03 1026.723)" fill="#00a63c" fontSize="16.335" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-5.799" y="0">H</tspan></text>
                  <text id="_2" data-name="2" transform="translate(854.547 1026.723)" fill="#00a63c" fontSize="7.753" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-2.09" y="0">2</tspan></text>
                </g>
              </svg>
              <p className="mt-[4vw] text-[1.8vw] font-noto font-medium 1440xl:mt-[3.6rem] 1440xl:text-[30px]">高效的<br />能量利用率 </p>
              <div className="text-justify mt-[3vw] mr-[2vw] ml-[2vw] w-[15vw] text-[1.18vw] font-noto font-thin 1440xl:mt-[3rem] 1440xl:mr-[2rem] 1440xl:ml-[2rem] 1440xl:w-[12rem] 1440xl:text-[17px] tracking-[0.14em]"><p>独特的等离子炬设计带来的高转化率和高热效率，这意味着该过程的能量利用率较高，继而有效降低生产成本。</p>
              </div>
            </div>
            <div className="w-[18vw] h-[40vw] bg-[#F7F7F7] rounded-[1rem] ml-[2vw] flex flex-col items-center pt-[4.56vw] 1440xl:w-[18rem] 1440xl:h-[643px] 1440xl:ml-[2rem] 1440xl:py-[4.56rem]">
              <svg xmlns="http://www.w3.org/2000/svg" width="6vw" height="6.8vw" viewBox="0 0 29.417 35.744" className="1440xl:w-[6rem] 1440xl:h-[6.8rem]">
                <g id="组_3261" data-name="组 3261" transform="translate(-930.608 -1001.986)">
                  <path id="路径_9416" data-name="路径 9416" d="M945.317,1037.047l14.208-8.2v-16.406l-14.208-8.2-14.208,8.2v16.406l14.208,8.2" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <line id="直线_247" data-name="直线 247" y2="13.83" transform="translate(933.946 1013.879)" fill="none" stroke="#00a63c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <text id="c" transform="translate(944.627 1026.986)" fill="#00a63c" fontSize="23.512" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-5.43" y="0">c</tspan></text>
                </g>
              </svg>
              <p className="mt-[4vw] text-[1.8vw] font-noto font-medium 1440xl:mt-[3.6rem] 1440xl:text-[30px]">产物应用<br />优势明显 </p>
              <div className="text-center mt-[3vw] mr-[2vw] ml-[2vw] w-[15vw] text-[1.18vw] font-noto font-thin 1440xl:mt-[3rem] 1440xl:mr-[2rem] 1440xl:ml-[2rem] 1440xl:w-[12rem] 1440xl:text-[17px] tracking-[0.14em]"><p>可生产纳米级炭黑，同时通过工艺调节可以实现炭黑粒度分布调整，能有效匹配各个炭黑应用领域。</p>
              </div>
            </div>
            <div className="w-[18vw] h-[40vw] bg-[#F7F7F7] rounded-[1rem] ml-[2vw] flex flex-col items-center pt-[4.56vw] 1440xl:w-[18rem] 1440xl:h-[643px] 1440xl:ml-[2rem] 1440xl:py-[4.56rem]">
              <svg xmlns="http://www.w3.org/2000/svg" width="6vw" height="6.8vw" viewBox="0 0 33.069 30.809" className="1440xl:w-[6rem] 1440xl:h-[6.8rem]">
                <g id="组_3259" data-name="组 3259" transform="translate(-1024.972 -1007.761)">
                  <path id="路径_9404" data-name="路径 9404" d="M1048.475,1016.864l3.635-6.292-2.329,1.351s-.911-1.593-1.276-2.22a2.849,2.849,0,0,0-4.962.012s-.835,1.439-1.674,2.9l1.692,2.929-2.353,1.321Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9405" data-name="路径 9405" d="M1037.444,1008.261a2.572,2.572,0,0,0-2.181,1.273c-.787,1.288-3.779,6.552-3.779,6.552l6.256,3.614s4.7-8.148,5.553-9.62a3.581,3.581,0,0,1,3.131-1.819Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9406" data-name="路径 9406" d="M1044.224,1031.776l3.632,6.294-.006-2.692s1.835.007,2.56.005a2.85,2.85,0,0,0,2.471-4.3s-.828-1.443-1.673-2.9h-3.383l.032-2.7-3.633,6.293Z" fill="none" stroke="#00a63c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9407" data-name="路径 9407" d="M1057.19,1026.525a2.573,2.573,0,0,0-.012-2.526c-.722-1.326-3.785-6.549-3.785-6.549l-6.258,3.611s4.707,8.144,5.555,9.62a3.579,3.579,0,0,1,.009,3.621l4.49-7.777Z" fill="none" stroke="#00a63c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9408" data-name="路径 9408" d="M1032.739,1020.081l-7.267,0,2.335,1.341s-.924,1.585-1.285,2.215a2.85,2.85,0,0,0,2.492,4.291s1.664,0,3.347,0l1.691-2.93,2.321,1.377-3.634-6.293Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9409" data-name="路径 9409" d="M1030.8,1033.935a2.573,2.573,0,0,0,2.193,1.252c1.509.038,7.564,0,7.564,0l0-7.224s-9.406,0-11.108,0a3.58,3.58,0,0,1-3.141-1.8l4.49,7.777Z" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="路径_9410" data-name="路径 9410" d="M1037.113,1017.359" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </g>
              </svg>
              <p className="mt-[4vw] text-[1.8vw] font-noto font-medium 1440xl:mt-[3.6rem] 1440xl:text-[30px]">工艺过程 <br /> 绿色环保 </p>
              <div className="text-center mt-[3vw] mr-[2vw] ml-[2vw] w-[15vw] text-[1.18vw] font-noto font-thin 1440xl:mt-[3rem] 1440xl:mr-[2rem] 1440xl:ml-[2rem] 1440xl:w-[12rem] 1440xl:text-[17px] tracking-[0.14em]"><p>无CO₂排放的工艺结合绿色能源，可做到真正意义上的绿氢和绿色炭黑制备；与传统的炭黑生产工艺相比，本工艺现场洁净、对环境友好，符合绿色化学的原则。</p>
              </div>
            </div>
            <div className="w-[18vw] h-[40vw] bg-[#F7F7F7] rounded-[1rem] ml-[2vw] flex flex-col items-center pt-[4.56vw] 1440xl:w-[18rem] 1440xl:h-[643px] 1440xl:ml-[2rem] 1440xl:py-[4.56rem]">
              <svg xmlns="http://www.w3.org/2000/svg" width="6vw" height="6.8vw" viewBox="0 0 37.319 31.942" className="1440xl:w-[6rem] 1440xl:h-[6.8rem]">
                <g id="组_3263" data-name="组 3263" transform="translate(-1120.653 -1006.335)">
                  <line id="直线_248" data-name="直线 248" x2="22.155" transform="translate(1121.153 1037.777)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <g id="组_3262" data-name="组 3262">
                    <path id="路径_9417" data-name="路径 9417" d="M1123.384,1037.777v-27.932a3.01,3.01,0,0,1,3.01-3.01h11.674a3.01,3.01,0,0,1,3.009,3.01v27.932" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <line id="直线_249" data-name="直线 249" x2="10.325" transform="translate(1126.861 1013.307)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <path id="路径_9418" data-name="路径 9418" d="M1141.077,1016.576h1.245a3.632,3.632,0,0,1,3.632,3.632v8.522a3.632,3.632,0,0,0,3.632,3.632h0a3.632,3.632,0,0,0,3.632-3.632v-13.394" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <path id="矩形_611" data-name="矩形 611" d="M0,0H8.509a0,0,0,0,1,0,0V2.866A2.184,2.184,0,0,1,6.325,5.05H2.184A2.184,2.184,0,0,1,0,2.866V0A0,0,0,0,1,0,0Z" transform="translate(1148.963 1010.286)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <line id="直线_250" data-name="直线 250" y1="3.424" transform="translate(1151.35 1006.844)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <line id="直线_251" data-name="直线 251" y1="3.424" transform="translate(1155.086 1006.844)" fill="none" stroke="#036eb7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <text id="H" transform="translate(1130.788 1028.598)" fill="#00a63c" fontSize="12.331" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-4.377" y="0">H</tspan></text>
                    <text id="_2" data-name="2" transform="translate(1136.462 1028.597)" fill="#00a63c" fontSize="5.853" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.1em"><tspan x="-1.578" y="0">2</tspan></text>
                  </g>
                </g>
              </svg>
              <p className="mt-[4vw] text-[1.8vw] font-noto font-medium 1440xl:mt-[3.6rem] 1440xl:text-[30px]">应用场景 <br /> 灵活多样 </p>
              <div className="text-center mt-[3vw] mr-[2vw] ml-[2vw] w-[15vw] text-[1.18vw] font-noto font-thin 1440xl:mt-[3rem] 1440xl:mr-[2rem] 1440xl:ml-[2rem] 1440xl:w-[12rem] 1440xl:text-[17px] tracking-[0.14em]"><p>撬装设备可从千瓦级别到兆瓦级别可灵活组合，从分布式制氢到工业化制氢可任意调整，极大满足各种需求量及需求目的下的应用场合。</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid place-items-center">
        <div className='flex w-full max-w-screen-lg text-[2vw] 1440xl:text-[2rem] mb-[10rem] '>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>天然气</p>
            <div className="relative">
              <img className="w-[22vw] 1440xl:w-[22rem] " src="trq.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>等离子</p>
            <div className="relative">
              <img className="w-[11vw] mt-[7vw] 1440xl:w-[11rem] 1440xl:mt-[7rem] " src="dlz.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center ml-[6rem] w-wull font-noto font-thin'>
            <p>炭黑</p>
            <div className="relative ">
              <img className="w-[7vw] mt-[5vw] 1440xl:w-[7rem] 1440xl:mt-[5rem]" src="th.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center ml-[5vw] mt-[8vw] 1440xl:mt-[8rem]  1440xl:ml-[5rem] font-noto font-thin'>
            <div className="relative">
              <img className="w-[3vw] h-[5vw] 1440xl:w-[3rem] 1440xl:h-[5rem]" src="+.png" alt="天然气图片1" />
            </div>
          </div>
          <div className='flex flex-col items-center font-noto font-thin'>
            <p>氢气</p>
            <div className="relative">
              <img className="w-[15vw] 1440xl:w-[15rem] " src="qq.png" alt="天然气图片1" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>


  )
}

export default product
