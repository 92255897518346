import React from 'react'
import Header from '../widgets/Header'
import Footer from '../widgets/Footer'
function teaminfo() {
  return (
    <div className='font-noto-sans-sc flex flex-col items-center'>
      <Header />
      <div className="relative w-full h-[30vw] 1440xl:h-[432px] overflow-hidden mt-[6.2vw] 1440xl:mt-[5.5rem]">
        <div className="absolute top-0 left-0 w-full h-[200%] overflow-hidden">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="team.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white font-noto-sans-sc leading-none">
            <h1 className='font-noto font-regular tracking-[0.8rem] 1440xl:tracking-[0.8vw] text-white text-[4.1vw] 1440xl:text-[3.75rem]'>关于我们</h1>
            <p className="font-noto font-regular tracking-[0.14rem] 1440xl:mt-[28.8px] mt-[2.4vw] text-[2vw] 1440xl:text-[1.84rem]">About us</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center h-auto pt-[10vw] 1440xl:pt-[175px] font-noto font-regular w-full max-w-[1440px] mx-[3rem] mb-[10rem]">
        <div className='w-full mx-[10vw] 1440xl:mx-[10rem]'>
          <p className='text-left text-[3vw] 2xl:text-[3rem] mb-[1.375rem] font-noto font-bold '>杭州慕皓</p>
          <p className='text-left text-[1.5vw] 2xl:text-[1.5rem] font-noto font-thin'>
          杭州慕皓成立于2022年，是一家聚焦于等离子技术的科技型初创公司。团队成员拥有深厚的等离子技术的底蕴及化工领域的经验，用绿色能源结合等离子方法，提供各种无二氧化碳排放的新材料及工艺技术。经过日以继夜地探索，我们已经走出了实验室的论证阶段，并取得了完美的成果，正在向中试与工业化放大阶段迈进。
          </p>
        </div>
      </div>
      <Footer />
    </div>


  )
}

export default teaminfo
