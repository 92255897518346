import React, { useState } from 'react';
import axios from 'axios';
import Header from '../widgets/Header';
import Footer from '../widgets/Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://mu-hawk.com/wp-json/myplugin/v2/submit-comment', formData);
      console.log('Comment submitted successfully:', response.data);
      // Optionally, you can reset the form data here
      setFormData({
        name: '',
        title: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
    <div className="">
      <Header />
      <div className="relative w-full  h-[30vw] 1440xl:h-[432px] overflow-hidden mt-[6.2vw] 1440xl:mt-[5.5rem] ">
        <img className="w-full h-full object-cover object-left-bottom" src="contact.png" alt="Contact Background" />
        <div className="absolute inset-0  flex items-center justify-center text-center w-full">
          <div className="text-white font-noto-sans-sc leading-none">
            <p className="text-right font-noto font-regular tracking-[0.9rem] 1440xl:tracking-[0.8vw] text-white text-[4.1vw] 1440xl:text-[3.3rem]">联系我们</p>
            <p className="text-center font-noto font-regular tracking-[0.14rem]  1440xl:mt-[28.8px] mt-[2.4vw] text-[2vw] 1440xl:text-[1.84rem]">Contact us</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex h-auto 1440xl:py-[117px] py-[8.125000vw]">
          <div className="1440xl:h-[42.06rem] h-[46.733333vw] 1440xl:w-[733px] w-[50.902778vw]">
            <form onSubmit={handleSubmit}>
              <div className="flex">
                <div className="text-left max-w-md 1440xl:mx-[0.812rem] mx-[0.902222vw] 1440xl:mt-10 mt-[2.7vw]">
                  <h1 className="1440xl:text-[18px] text-[1.250000vw] 1440xl:mb-4 mb-[1.111111vw] font-noto font-light">姓 名</h1>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="bg-[#F2F2F2] 1440xl:w-[349px] w-[24.236111vw] 1440xl:px-3 px-[3.333333vw] 1440xl:py-2 py-[0.138889vw] border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className="text-left max-w-md 1440xl:mx-[0.812rem] mx-[0.902222vw] 1440xl:mt-10 mt-[2.7vw]">
                  <h1 className="1440xl:text-[18px] text-[1.250000vw] 1440xl:mb-4 mb-[1.111111vw] font-noto font-light">称 呼</h1>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    className="bg-[#F2F2F2] 1440xl:w-[349px] w-[24.236111vw] 1440xl:px-3 px-[3.333333vw] 1440xl:py-2 py-[0.138889vw] border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-500"
                  />
                </div>
              </div>
              <div className="flex">
                <div className="text-left max-w-md 1440xl:mx-[0.812rem] mx-[0.902222vw] 1440xl:mt-10 mt-[2.7vw]">
                  <h1 className="1440xl:text-[18px] text-[1.250000vw] 1440xl:mb-4 mb-[1.111111vw] font-noto font-light">邮 箱</h1>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="bg-[#F2F2F2] 1440xl:w-[349px] w-[24.236111vw] 1440xl:px-3 px-[3.333333vw] 1440xl:py-2 py-[0.138889vw] border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className="text-left max-w-md 1440xl:mx-[0.812rem] mx-[0.902222vw] 1440xl:mt-10 mt-[2.7vw]">
                  <h1 className="1440xl:text-[18px] text-[1.250000vw] 1440xl:mb-4 mb-[1.111111vw] font-noto font-light">联 系 电 话</h1>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="bg-[#F2F2F2] 1440xl:w-[349px] w-[24.236111vw] 1440xl:px-3 px-[3.333333vw] 1440xl:py-2 py-[0.138889vw] border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-500"
                  />
                </div>
              </div>
              <div className="text-left 1440xl:mx-[0.812rem] mx-[0.902222vw] 1440xl:mt-10 mt-[2.7vw]">
                <h1 className="1440xl:text-[18px] text-[1.250000vw] 1440xl:mb-4 mb-[1.111111vw] font-noto font-light">留 言</h1>
                <input
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="bg-[#F2F2F2] 1440xl:w-[733px] w-[50.902778vw] 1440xl:h-[16.68rem] h-[18.533333vw] 1440xl:px-3 px-[3.333333vw] 1440xl:py-2 py-[0.138889vw] border border-gray-300 shadow-sm focus:outline-none focus:border-indigo-500"
                />
              </div>
              <button
                type="submit"
                className="1440xl:mt-[3.96rem] mt-[4.400000vw] 1440xl:mx-[0.812rem] mx-[0.902222vw] flex items-center justify-center space-x-2 tracking-[0.4rem] bg-blue-500 1440xl:text-[1rem] text-[1.111111vw] text-white 1440xl:rounded-[0.748rem] rounded-[0.831111vw] 1440xl:px-4 px-[1vw] 1440xl:py-2 py-[0.5vw] 1440xl:w-[6.9rem] w-[7.666667vw] 1440xl:h-[2rem] h-[2.222222vw]"
              >
                <svg
                  className="1440xl:w-4 1440xl:h-4 w-[1vw] h-[1vw]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10.022 9.703"
                >
                  <path
                    id="路径_4830"
                    data-name="路径 4830"
                    d="M2679.111,754.718h8.017v-2.506h1v3.007a.5.5,0,0,1-.5.5h-9.019a.5.5,0,0,1-.5-.5v-3.007h1Zm6.1-6.013-1.98-1.979.709-.709,3.189,3.189-3.189,3.189-.709-.709,1.98-1.979h-5.6v-1Zm0,0"
                    transform="translate(-2678.108 -746.017)"
                    fill="#fff"
                  />
                </svg>
                <span className="1440xl:text-[1rem] text-[1vw]">发送</span>
              </button>
            </form>
          </div>
          <div className="1440xl:h-[42.06rem] h-[46.733333vw] 1440xl:w-[22.812rem] w-[25.346667vw] 1440xl:pt-20 pt-[1.388889vw] 1440xl:ml-[77px] ml-[5.347222vw]">
            <nav className="text-left font-noto font-regular">
              <p className="flex items-center 1440xl:pb-[0.375rem] pb-[0.416667vw] 1440xl:text-[1.75rem] text-[1.944444vw] whitespace-nowrap">杭州慕皓新能源技术有限公司</p>
              <p className="flex items-center 1440xl:pb-[2.375rem] pb-[2.638889vw] 1440xl:text-[0.875rem] text-[0.972222vw] whitespace-nowrap">Hangzhou MUHAWK Clean Energy Technology Co.,Ltd</p>
              <p className="flex items-center 1440xl:pb-[0.375rem] pb-[0.416667vw] 1440xl:text-[1.062rem] text-[1.180000vw] whitespace-nowrap">地址 : 浙江省杭州市上城区下车路10号2564</p>
              <p className="flex items-center 1440xl:pb-[8rem] pb-[8.888889vw] 1440xl:text-[1.062rem] text-[1.180000vw] whitespace-nowrap">邮编 : 310000</p>
              <p className="flex items-center 1440xl:text-[2.375rem] text-[2.638889vw] 1440xl:pb-[0.187rem] pb-[0.1vw] whitespace-nowrap">联系我们</p>
              <p className="flex items-center 1440xl:text-[0.562rem] text-[0.624444vw] 1440xl:h-[0.492rem] h-[0.546667vw] 1440xl:pb-[0.57rem] pb-[0.633333vw] whitespace-nowrap">CONTACT US</p>
              {/* <p className="flex items-center 1440xl:text-[0.562rem] text-[0.624444vw] 1440xl:h-[0.492rem] h-[0.546667vw] 1440xl:pb-[3.375rem] pb-[3.750000vw] whitespace-nowrap">US</p> */}
              <p className="flex items-center 1440xl:text-[2.937rem] text-[3.263333vw] 1440xl:h-[4.75rem] h-[5.277778vw] 1440xl:pb-[0.375rem] pb-[0.416667vw] whitespace-nowrap font-noto font-bold">0571-86088069</p>
              <p className="flex items-center 1440xl:text-[1.5rem] text-[1.666667vw] 1440xl:h-[1.6rem] h-[1.777778vw] whitespace-nowrap">Mail: info@mu-hawk.com</p>
            </nav>
          </div>
        </div>
      </div>
      <div className="w-full">
        <img className="w-full object-cover" src="https://www.freeimg.cn/i/2024/07/17/66976b6b867ae.png" alt="Map" />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;